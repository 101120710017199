<template>
  <div class="tw-p-6 sm:tw-p-10">
    <div class="tw-block sm:tw-flex tw-mb-6 tw-items-center tw-justify-between">
      <h5 class="tw-text-2xl inter-semibold tw-text-black tw-mb-3 sm:tw-mb-0">
        Salon
      </h5>
      <div>
        <!-- <Button
          label="Impor"
          icon="pi pi-cloud-download"
          iconPos="left"
          class="tw-h-9 tw-text-sm tw-mr-3 tw-mb-3 sm:tw-mb-0 tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300"
        />
        <Button
          label="Ekspor"
          icon="pi pi-cloud-upload"
          iconPos="left"
          class="tw-h-9 tw-text-sm tw-mr-3 tw-mb-3 sm:tw-mb-0 tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300"
        /> -->
        <Button
          label="Tambah"
          icon="pi pi-plus"
          class="tw-h-9 tw-text-sm tw-mb-3 sm:tw-mb-0 tw-bg-transparent tw-text-white tw-hover:tw-bg-neutral-300 tw-border-blue-light tw-bg-blue-light"
          @click="navigateToCreate"
        />
      </div>
    </div>

    <div class="tw-flex">
      <form @submit.prevent="doSearch()" class="tw-mb-8">
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText
            v-model="keyword"
            placeholder="Cari disini"
            class="tw-h-11 md:tw-w-80 tw-w-auto mr-3"
          />
        </span>
      </form>
      <Button
        label="Filter"
        type="submit"
        icon="pi pi-align-center"
        iconPos="left"
        @click="dialogFilter = true"
        class="tw-h-11 tw-text-sm tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300 tw-block"
      />
    </div>

    <div class="tw-hidden sm:tw-block">
      <DataTable
        :value="data"
        :paginator="true"
        :rows="10"
        dataKey="id"
        :rowHover="true"
        responsiveLayout="scroll"
        paginatorTemplate="FirstPageLink PageLinks LastPageLink"
      >
        <template #empty>
          <div
            class="tw-flex tw-justify-center tw-items-center tw-py-20 tw-text-2xl tw-font-bold"
          >
            Tidak ada data yang ditemukan
          </div>
        </template>
        <template #loading>
          <div class="tw-flex tw-justify-center tw-items-center tw-py-20">
            Loading records, please wait...
          </div>
        </template>
        <Column
          field="no"
          header="No."
          sortable
          style="min-width: 2rem"
        ></Column>
        <Column
          header="Nama"
          field="name"
          class="capitalize"
          sortable
          style="min-width: 12rem"
        ></Column>
        <Column
          header="Alamat"
          field="address"
          sortable
          style="min-width: 32rem"
        ></Column>
        <Column
          header="Provinsi"
          field="provinces_name"
          sortable
          style="min-width: 12rem"
        ></Column>
        <Column
          header="Kota"
          field="cities_name"
          sortable
          style="min-width: 16rem"
        ></Column>
        <Column
          header="Kecamatan"
          field="districts_name"
          sortable
          style="min-width: 12rem"
        ></Column>
        <Column
          header="Status"
          field="status"
          sortable
          style="min-width: 11rem"
        >
          <template #body="{ data }">
            <Tag
              class="mr-2 status"
              :class="{
                'status-warning': data.status === 0,
                'status-info': data.status === 1,
                'status-danger': data.status === 2 || data.status === 4,
                'status-success': data.status === 3,
              }"
              :value="data.status_name"
            >
            </Tag>
          </template>
        </Column>
        <Column header="Aksi" style="min-width: 8rem">
          <template #body="{ data }">
            <a
              :href="`${data.id}`"
              class="tw-mr-4"
              @click.prevent="detailData(data.id)"
            >
              <i class="pi pi-eye tw-text-lg"></i>
            </a>
          </template>
        </Column>
      </DataTable>
    </div>

    <div class="tw-block sm:tw-hidden">
      <div
        class="card tw-p-0 tw-mb-5"
        v-for="(item, i) in card_data"
        :key="`item_${i}`"
      >
        <div class="tw-px-5 tw-py-3">
          <div class="tw-flex tw-justify-between">
            <h6 class="tw-capitalize tw-mb-3 tw-pt-1">{{ item.name }}</h6>
            <a href="#" @click.prevent="toogle($event, item.id)">
              <i class="pi pi-ellipsis-v"></i>
            </a>
            <Menu ref="menu" id="overlay_menu" :model="menus" :popup="true" />
          </div>
          <hr class="mb-3" />
          <div class="tw-flex tw-justify-between tw-mb-1">
            <h6 class="tw-capitalize opacity-60 tw-text-sm tw-mr-3">
              Provinsi
            </h6>
            <h6 class="tw-capitalize opacity-60 tw-text-sm">
              {{ item.provinces_name }}
            </h6>
          </div>
          <div class="tw-flex tw-justify-between tw-mb-1">
            <h6 class="tw-capitalize opacity-60 tw-text-sm tw-mr-3">Kota</h6>
            <h6 class="tw-capitalize opacity-60 tw-text-sm">
              {{ item.cities_name }}
            </h6>
          </div>
          <div class="tw-flex tw-justify-between tw-mb-1">
            <h6 class="tw-capitalize opacity-60 tw-text-sm tw-mr-3">
              Kecamatan
            </h6>
            <h6 class="tw-capitalize opacity-60 tw-text-sm">
              {{ item.districts_name }}
            </h6>
          </div>
          <div class="tw-flex tw-justify-between">
            <h6 class="tw-capitalize opacity-60 tw-text-sm tw-mr-3">Status</h6>
            <div>
              <Tag
                class="mr-2 status"
                :class="{
                  'status-warning': item.status === 0,
                  'status-info': item.status === 1,
                  'status-danger': item.status === 2 || data.status === 4,
                  'status-success': item.status === 3,
                }"
                :value="item.status_name"
              >
              </Tag>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        v-model:visible="dialogFilter"
        modal
        header="Filter Salon"
        :style="{ width: '25rem' }"
      >
        <div class="form-field" v-show="!isAdminPriti">
          <label for="salon_name" class="tw-font-semibold">Nama Salon</label>
          <InputText
            id="salon_name"
            v-model="filter.salon_name"
            class="tw-mt-1"
            autocomplete="off"
            placeholder="Nama Salon"
          />
        </div>
        <div class="form-field tw-mt-3">
          <label for="province" class="tw-font-semibold">Provinsi</label>
          <Dropdown
            id="province"
            class="tw-mt-1"
            :options="provinces"
            placeholder="Pilih Provinsi"
            optionLabel="name"
            v-model="filter.province_selected"
            @change="getCitys(province_selected)"
          />
        </div>
        <div class="form-field tw-mt-3">
          <label for="city" class="tw-font-semibold">Kota</label>
          <Dropdown
            id="city"
            class="tw-mt-1"
            :options="cities"
            v-model="filter.city_selected"
            placeholder="Pilih Kota/Kabupaten"
            optionLabel="name"
            @change="getDistricts(city_selected)"
          />
        </div>
        <div class="form-field tw-mt-3">
          <label for="district" class="tw-font-semibold">Kecamatan</label>
          <Dropdown
            id="district"
            class="tw-mt-1"
            :options="districts"
            v-model="filter.district_selected"
            placeholder="Pilih Kecamatan"
            optionLabel="name"
          />
        </div>
        <!-- status checkbox -->
        <div class="form-field tw-mt-3">
          <label for="status" class="tw-font-semibold">Status</label>
          <div class="tw-flex tw-items-start tw-gap-2 tw-mb-2 tw-mt-2">
            <input
              type="checkbox"
              v-model="filter.status.active"
              class="tw-h-5 tw-w-5 tw-rounded-full tw-border-2 tw-border-gray-200"
            />
            <label class="tw-text-sm tw-font-semibold" for="">Aktif</label>
          </div>
          <div class="tw-flex tw-items-start tw-gap-2 tw-mb-2 tw-mt-2">
            <input
              type="checkbox"
              v-model="filter.status.non_active"
              class="tw-h-5 tw-w-5 tw-rounded-full tw-border-2 tw-border-gray-200"
            />
            <label class="tw-text-sm tw-font-semibold" for="">Non Aktif</label>
          </div>
          <div class="tw-flex tw-items-start tw-gap-2 tw-mb-2 tw-mt-2">
            <input
              type="checkbox"
              v-model="filter.status.approve"
              class="tw-h-5 tw-w-5 tw-rounded-full tw-border-2 tw-border-gray-200"
            />
            <label class="tw-text-sm tw-font-semibold" for="">Approve</label>
          </div>
          <div class="tw-flex tw-items-start tw-gap-2 tw-mb-2 tw-mt-2">
            <input
              type="checkbox"
              v-model="filter.status.pending"
              class="tw-h-5 tw-w-5 tw-rounded-full tw-border-2 tw-border-gray-200"
            />
            <label class="tw-text-sm tw-font-semibold" for="">Pending</label>
          </div>
        </div>
        <div class="flex justify-content-end gap-2 tw-mt-4">
          <Button
            type="button"
            label="Reset"
            class="tw-bg-transparent tw-text-black tw-border-gray-300"
            @click="clearFilter()"
          />
          <Button type="button" label="Terapkan" @click="loadDataFilter()" />
        </div>
      </Dialog>

      <Paginator
        :rows="limit"
        :totalRecords="data.length"
        @page="changePage"
        template="FirstPageLink PageLinks LastPageLink"
      >
      </Paginator>
    </div>
  </div>
</template>

<script>
import { useAuthRole } from "@/composables/auth";

export default {
  props: {},
  data() {
    return {
      keyword: "",
      dialogFilter: false,
      offset: 0,
      limit: 10,
      salons: [],
      data: [],
      card_data: [],
      current_id: null,
      isAdminPriti: useAuthRole().isAdminPriti,
      provinces: [],
      cities: [],
      districts: [],
      filter: {
        salon_name: "",
        province_selected: null,
        city_selected: null,
        district_selected: null,
        status: {
          active: false,
          non_active: false,
          approve: false,
          pending: false,
        },
      },
      menus: [
        {
          label: "Lihat Detail",
          icon: "pi pi-eye",
          command: () => {
            this.detailData(this.current_id);
          },
        },
      ],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    navigateToCreate() {
      this.$router.push({ name: "create-salon" });
    },

    detailData(id) {
      this.$router.push({ name: "detail-salon", params: { id: id } });
    },

    toogle(event, id) {
      this.current_id = id;
      this.$refs.menu.toggle(event);
    },

    async loadData() {
      const url = this.setFilter();
      try {
        const response = await this.$http.get(`${url}`);
        this.salons = response.data.data.salons;
        this.salons.forEach((item, idx) => {
          item.no = idx + 1;
        });
        this.data = this.salons;
        this.changePage({ page: 0 });

        await this.getProvinces();
      } catch (error) {
        console.error("Failed to load data:", error);
        throw error;
      }
    },

    setFilter() {
      let url = `${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/salons/dashboard/?`;
      if (this.filter.salon_name) {
        url += `salon_name=${this.filter.salon_name}&`;
      }
      if (this.filter.province_selected) {
        url += `provinces_id=${this.filter.province_selected.id}&`;
      }
      if (this.filter.city_selected) {
        url += `cities_id=${this.filter.city_selected.id}&`;
      }
      if (this.filter.district_selected) {
        url += `districts_id=${this.filter.district_selected.id}&`;
      }

      const statusFilters = [];

      if (this.filter.status.active) statusFilters.push(3);
      if (this.filter.status.non_active) statusFilters.push(2);
      if (this.filter.status.approve) statusFilters.push(1);
      if (this.filter.status.pending) statusFilters.push(0);

      if (statusFilters.length > 0) {
        const statusString = statusFilters.join(",");
        url += `status=${statusString}&`;
      }
      return url;
    },

    async loadDataFilter() {
      try {
        await this.loadData();
        this.dialogFilter = false;
      } catch (error) {
        console.error("Error during apply:", error);
      }
    },

    async getProvinces() {
      try {
        const response = await this.$http.get(
          `${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/locations/ddl/provinces`
        );
        if (response.data.code === 200) {
          this.provinces = response.data.data.provinces;
        } else {
          console.error("Failed to fetch provinces:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching provinces:", error);
      }
    },

    async getCitys() {
      try {
        const response = await this.$http.get(
          `${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/locations/ddl/cities?province_id=${this.filter.province_selected.id}`
        );
        if (response.data.code === 200) {
          this.cities = response.data.data.cities;
        } else {
          console.error("Failed to fetch cities:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    },

    async getDistricts() {
      try {
        const response = await this.$http.get(
          `${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/locations/ddl/districts?province_id=${this.filter.province_selected.id}&city_id=${this.filter.city_selected.id}`
        );
        if (response.data.code === 200) {
          this.districts = response.data.data.districts;
        } else {
          console.error("Failed to fetch districts:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    },

    doSearch() {
      let keyword = this.keyword.toLowerCase();
      this.data = this.salons.filter(
        (item) =>
          item.name.toLowerCase().includes(keyword) ||
          item.provinces_name.toLowerCase().includes(keyword) ||
          item.cities_name.toLowerCase().includes(keyword) ||
          item.districts_name.toLowerCase().includes(keyword) ||
          item.status_name.toLowerCase().includes(keyword)
      );
      this.card_data = this.data;
      this.changePage({ page: 0 });
    },

    changePage(event) {
      let page = event.page + 1;
      let offset = (page - 1) * this.limit;
      let dataLength = this.data.length;

      let breakPoint =
        page <= Math.floor(dataLength / this.limit)
          ? offset + this.limit
          : offset + (dataLength % this.limit);

      this.card_data = [];
      for (let i = offset; i < breakPoint; i++) {
        const element = this.data[i];
        this.card_data.push(element);
      }
    },

    clearFilter() {
      this.filter = {
        salon_name: "",
        province_selected: null,
        city_selected: null,
        district_selected: null,
        status: {
          active: false,
          non_active: false,
          approve: false,
          pending: false,
        },
      };
    },
  },
};
</script>

<style scoped lang="scss">
.form-field {
  display: flex;
  flex-direction: column;
}
</style>
